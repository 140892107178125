import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home/> },
    { path: '/login/admin', element: <Login/> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/company', element: <CompanyLogin/> },
    { path: '/login/clinic', element: <ClinicLogin/> },
    { path: '/forget', element: <Register/> },
    { path: '/reset-passsword', element: <ResetPassword usertype={'company'}/> },
    { path: '/clinic-reset-passsword', element: <ResetPassword usertype={'clinic'}/> },
    {
          path: 'dashboard',
          element: (
              <DashboardLayout />
          ),
          children: [

            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'home', element: <GeneralAnalytics /> },
            { path: 'dorm', element: <Dorm /> },
            { path: 'editdorm/:id', element: <EditDorm /> },
            { path: 'adddorm', element: <AddDorm /> },
            { path: 'element', element: <Element /> },
            { path: 'editelement/:id', element: <EditElement /> },
            { path: 'comment/:id', element: <Comment /> },
            { path: 'companyProfile', element: <CompanyProfile /> },
            { path: 'addelement', element: <AddElement /> },
            { path: 'rarity', element: <Rarity /> },
            { path: 'editrarity/:id', element: <EditRarity /> },
            { path: 'addrarity', element: <AddRarity /> },
             { path: 'spell', element: <Spell /> },
             { path: 'editspell/:id', element: <EditSpell /> },
             { path: 'editadmin', element: <EditAdmin /> },
            {
              path: 'e-commerce',
              children: [
                { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
              ],
            },
          ],
        },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));


const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Dorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/Dorm')));
const EditDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/EditDorm')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/AddDorm')));

const Element = Loadable(lazy(() => import('../pages/dashboard/Company/Element')));
const EditElement = Loadable(lazy(() => import('../pages/dashboard/Company/EditElement')));
const Comment = Loadable(lazy(() => import('../pages/dashboard/Company/Comment')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));
const AddElement = Loadable(lazy(() => import('../pages/dashboard/Company/AddElement')));



const Rarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/AddRarity')));
const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));

const Spell = Loadable(lazy(() => import('../pages/dashboard/Provider/Spell')));
const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/EditSpell')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));


